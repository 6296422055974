<template>
    <div style="max-width:100%">
        <v-data-table
            :headers="filteredColumnHeaders"
            :items="columnsFetching ? [] : currentColumns"
            :search="columnSearch"
            :loading="columnsFetching"
            item-key="short_id"
            :items-per-page="10"
            :sort-desc="[true, true]"
            class="text-truncate"
        >
            <template v-slot:top>
                <v-row align="center" no-gutters>
                    <v-col cols="6">
                        <div class="d-flex align-center">
                            <v-icon>mdi-table-large</v-icon>
                            <span class="primary--text font-weight-bold subtitle-1 ml-1 text-truncate">{{ currentTableSelected }}</span>
                        </div>
                    </v-col>
                    <v-col cols="6">
                        <v-text-field v-model="columnSearch" append-icon="search" label="Filter" single-line></v-text-field>
                    </v-col>
                </v-row>
            </template>
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="item in items" :key="item.cid" @mouseover="setHoveredColumn(item, 'column')">
                        <td>
                            <v-menu left origin="center center" transition="scale-transition" max-width="30%" nudge-bottom="30">
                                <template v-slot:activator="{ on }">
                                    <div class="d-flex">
                                        <a v-on="on" class="secondary--text subtitle-2">{{ item.short_id }}</a>
                                    </div>
                                </template>
                                <v-list style="overflow-y:hidden" nav dense>
                                    <CopyToClipboard
                                        :textToCopy="quotedName(item.short_id)"
                                        buttonName="Copy name to clipboard"
                                        type="listItem"
                                    ></CopyToClipboard>
                                </v-list>
                            </v-menu>
                        </td>
                        <td>
                            {{ item.coltype }}
                        </td>
                        <td style="max-width:800px !important">
                            <v-tooltip max-width="500" bottom>
                                <template v-slot:activator="{ on }">
                                    <div class="text-truncate" v-on="on">
                                        {{ item.description ? item.description : 'no description available' }}
                                    </div>
                                </template>
                                <span>{{ item.description ? item.description : 'no description available' }}</span>
                            </v-tooltip>
                        </td>

                        <td v-if="isDevelopment === true">
                            <div v-if="item === selectedColumn">
                                <v-menu :close-on-content-click="false" :close-on-click="false" bottom origin="center center" transition="scale-transition">
                                    <template v-slot:activator="{ on }">
                                        <v-btn v-on="on" text color="primary" small>
                                            <v-icon>more_horiz</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-card>
                                        <v-card-text>
                                            <div class="d-flex align-start flex-column">
                                                <RenameButton
                                                    :selected="[item]"
                                                    objectType="column"
                                                    :dispatchFunction="
                                                        () => {
                                                            $store.dispatch('snapshotStore/fetchColumnAndPreviewData', item.tid)
                                                        }
                                                    "
                                                ></RenameButton>
                                                <DeleteButton
                                                    :selected="[item]"
                                                    objectType="columns"
                                                    :dispatchFunction="
                                                        () => {
                                                            $store.dispatch('snapshotStore/fetchColumnAndPreviewData', item.tid)
                                                        }
                                                    "
                                                ></DeleteButton>
                                                <EditDescriptionButton
                                                    :selected="[item]"
                                                    objectType="column"
                                                    :dispatchFunction="
                                                        () => {
                                                            $store.dispatch('snapshotStore/fetchColumnAndPreviewData', item.tid)
                                                        }
                                                    "
                                                ></EditDescriptionButton>
                                            </div>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                            </div>
                        </td>
                    </tr>
                    <tr v-if="!items.length">
                        <td colspan="6">
                            <v-alert :value="true" color="info" icon="info" text class="mt-3">
                                <span v-if="columnsFetching">Loading the list of columns, please wait...</span>
                                <span v-else>No columns found.</span>
                            </v-alert>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:no-results>
                <v-alert :value="true" color="error" icon="warning" outlined class="mt-3">Your search for "{{ search }}" found no results.</v-alert>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
const RenameButton = () => import('../components/TheSnapshotRenameButton')
const DeleteButton = () => import('../components/TheSnapshotDeleteButton')
const EditDescriptionButton = () => import('../components/TheSnapshotTableColDescriptionEdit')
const CopyToClipboard = () => import('@/components/CopyToClipboard')
export default {
    name: 'snapshot-table-columns',
    components: {
        RenameButton,
        DeleteButton,
        EditDescriptionButton,
        CopyToClipboard
    },
    props: {
        currentTableSelected: String
    },
    data() {
        return {
            columnSearch: '',
            columnHeaders: [
                { text: 'Name', align: 'left', value: 'short_id' },
                { text: 'Type', align: 'left', value: 'coltype', width: '10%' },
                { text: 'Description', align: 'left', value: 'description' },
                { text: 'Actions', align: 'center', value: 'actions', width: '5%' }
            ],
            selectedColumn: null
        }
    },
    computed: {
        ...mapState('snapshotStore', ['currentColumns', 'columnsFetching']),
        ...mapGetters('snapshotStore', ['isDevelopment']),
        filteredColumnHeaders() {
            if (this.isDevelopment) {
                return this.$data.columnHeaders
            } else {
                return this.$data.columnHeaders.filter(column => column.text !== 'Actions')
            }
        }
    },
    methods: {
        quotedName: function(name) {
            return '"' + name + '"'
        },
        setHoveredColumn(item, itemType) {
            this.selectedColumn = item
        }
    },
    deactivated() {
        this.$data.columnSearch = ''
    }
}
</script>
