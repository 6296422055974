var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { "max-width": "100%" } },
    [
      _c("v-data-table", {
        staticClass: "text-truncate",
        attrs: {
          headers: _vm.filteredColumnHeaders,
          items: _vm.columnsFetching ? [] : _vm.currentColumns,
          search: _vm.columnSearch,
          loading: _vm.columnsFetching,
          "item-key": "short_id",
          "items-per-page": 10,
          "sort-desc": [true, true]
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function() {
              return [
                _c(
                  "v-row",
                  { attrs: { align: "center", "no-gutters": "" } },
                  [
                    _c("v-col", { attrs: { cols: "6" } }, [
                      _c(
                        "div",
                        { staticClass: "d-flex align-center" },
                        [
                          _c("v-icon", [_vm._v("mdi-table-large")]),
                          _c(
                            "span",
                            {
                              staticClass:
                                "primary--text font-weight-bold subtitle-1 ml-1 text-truncate"
                            },
                            [_vm._v(_vm._s(_vm.currentTableSelected))]
                          )
                        ],
                        1
                      )
                    ]),
                    _c(
                      "v-col",
                      { attrs: { cols: "6" } },
                      [
                        _c("v-text-field", {
                          attrs: {
                            "append-icon": "search",
                            label: "Filter",
                            "single-line": ""
                          },
                          model: {
                            value: _vm.columnSearch,
                            callback: function($$v) {
                              _vm.columnSearch = $$v
                            },
                            expression: "columnSearch"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            },
            proxy: true
          },
          {
            key: "body",
            fn: function(ref) {
              var items = ref.items
              return [
                _c(
                  "tbody",
                  [
                    _vm._l(items, function(item) {
                      return _c(
                        "tr",
                        {
                          key: item.cid,
                          on: {
                            mouseover: function($event) {
                              return _vm.setHoveredColumn(item, "column")
                            }
                          }
                        },
                        [
                          _c(
                            "td",
                            [
                              _c(
                                "v-menu",
                                {
                                  attrs: {
                                    left: "",
                                    origin: "center center",
                                    transition: "scale-transition",
                                    "max-width": "30%",
                                    "nudge-bottom": "30"
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "div",
                                              { staticClass: "d-flex" },
                                              [
                                                _c(
                                                  "a",
                                                  _vm._g(
                                                    {
                                                      staticClass:
                                                        "secondary--text subtitle-2"
                                                    },
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(item.short_id)
                                                    )
                                                  ]
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c(
                                    "v-list",
                                    {
                                      staticStyle: { "overflow-y": "hidden" },
                                      attrs: { nav: "", dense: "" }
                                    },
                                    [
                                      _c("CopyToClipboard", {
                                        attrs: {
                                          textToCopy: _vm.quotedName(
                                            item.short_id
                                          ),
                                          buttonName: "Copy name to clipboard",
                                          type: "listItem"
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          ),
                          _c("td", [_vm._v(" " + _vm._s(item.coltype) + " ")]),
                          _c(
                            "td",
                            {
                              staticStyle: { "max-width": "800px !important" }
                            },
                            [
                              _c(
                                "v-tooltip",
                                {
                                  attrs: { "max-width": "500", bottom: "" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "div",
                                              _vm._g(
                                                {
                                                  staticClass: "text-truncate"
                                                },
                                                on
                                              ),
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      item.description
                                                        ? item.description
                                                        : "no description available"
                                                    ) +
                                                    " "
                                                )
                                              ]
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                },
                                [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        item.description
                                          ? item.description
                                          : "no description available"
                                      )
                                    )
                                  ])
                                ]
                              )
                            ],
                            1
                          ),
                          _vm.isDevelopment === true
                            ? _c("td", [
                                item === _vm.selectedColumn
                                  ? _c(
                                      "div",
                                      [
                                        _c(
                                          "v-menu",
                                          {
                                            attrs: {
                                              "close-on-content-click": false,
                                              "close-on-click": false,
                                              bottom: "",
                                              origin: "center center",
                                              transition: "scale-transition"
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function(ref) {
                                                    var on = ref.on
                                                    return [
                                                      _c(
                                                        "v-btn",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              text: "",
                                                              color: "primary",
                                                              small: ""
                                                            }
                                                          },
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", [
                                                            _vm._v("more_horiz")
                                                          ])
                                                        ],
                                                        1
                                                      )
                                                    ]
                                                  }
                                                }
                                              ],
                                              null,
                                              true
                                            )
                                          },
                                          [
                                            _c(
                                              "v-card",
                                              [
                                                _c("v-card-text", [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "d-flex align-start flex-column"
                                                    },
                                                    [
                                                      _c("RenameButton", {
                                                        attrs: {
                                                          selected: [item],
                                                          objectType: "column",
                                                          dispatchFunction: function() {
                                                            _vm.$store.dispatch(
                                                              "snapshotStore/fetchColumnAndPreviewData",
                                                              item.tid
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _c("DeleteButton", {
                                                        attrs: {
                                                          selected: [item],
                                                          objectType: "columns",
                                                          dispatchFunction: function() {
                                                            _vm.$store.dispatch(
                                                              "snapshotStore/fetchColumnAndPreviewData",
                                                              item.tid
                                                            )
                                                          }
                                                        }
                                                      }),
                                                      _c(
                                                        "EditDescriptionButton",
                                                        {
                                                          attrs: {
                                                            selected: [item],
                                                            objectType:
                                                              "column",
                                                            dispatchFunction: function() {
                                                              _vm.$store.dispatch(
                                                                "snapshotStore/fetchColumnAndPreviewData",
                                                                item.tid
                                                              )
                                                            }
                                                          }
                                                        }
                                                      )
                                                    ],
                                                    1
                                                  )
                                                ])
                                              ],
                                              1
                                            )
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  : _vm._e()
                              ])
                            : _vm._e()
                        ]
                      )
                    }),
                    !items.length
                      ? _c("tr", [
                          _c(
                            "td",
                            { attrs: { colspan: "6" } },
                            [
                              _c(
                                "v-alert",
                                {
                                  staticClass: "mt-3",
                                  attrs: {
                                    value: true,
                                    color: "info",
                                    icon: "info",
                                    text: ""
                                  }
                                },
                                [
                                  _vm.columnsFetching
                                    ? _c("span", [
                                        _vm._v(
                                          "Loading the list of columns, please wait..."
                                        )
                                      ])
                                    : _c("span", [_vm._v("No columns found.")])
                                ]
                              )
                            ],
                            1
                          )
                        ])
                      : _vm._e()
                  ],
                  2
                )
              ]
            }
          },
          {
            key: "no-results",
            fn: function() {
              return [
                _c(
                  "v-alert",
                  {
                    staticClass: "mt-3",
                    attrs: {
                      value: true,
                      color: "error",
                      icon: "warning",
                      outlined: ""
                    }
                  },
                  [
                    _vm._v(
                      'Your search for "' +
                        _vm._s(_vm.search) +
                        '" found no results.'
                    )
                  ]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }